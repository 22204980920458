.frmlookupstock-divmain {
  height: 88vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

#frmlookupstock .table-container {
  width: 60%;
  height: 100%;
  margin: auto;
  text-align: center;
}

#frmlookupstock .searchtext {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  margin-bottom: 6px;
}

#frmlookupstock .search-input {
  padding: 10px;
  height: 20px;
  width: 50%;
  font-size: 16px;
}

#frmlookupstock .searchtext button {
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  font-size: 14px;
}

#frmlookupstock .mybtncontainer {
  display: flex;
  justify-content: center;
}

#frmlookupstock .mybtncontainer .mybtn {
  margin: 0 2px;
  padding: 8px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 12px;
  width: auto;
}

#frmlookupstock .mybtncontainer .btn-icon {
  padding-right: 2px;
}


#frmlookupstock .mybtncontainer .mybtn:hover {
  background-color: #0056b3;
}

#frmlookupstock .lookupTable {
  width: 100%;
  height: 85%;
  max-height: 85%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border: 1px solid gray;
}

#frmlookupstock .tabledata {
  width: 100%;
  height: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
}

#frmlookupstock .tabledata th,
.table td {
    border: 1px solid #ddd;
    padding: 2px;
}
  
#frmlookupstock .tabledata th {
  background-color: blue;
  color: white;
}

#frmlookupstock .row-data td {
  overflow: hidden;
}

#frmlookupstock .col-factoryname {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85px;
  width: 45%;
  padding-left: 2px;
}

#frmlookupstock .col-companyname {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85px;
  width: 40%;
  padding-left: 2px;
}

#frmlookupstock .col-action {
    width: 15%;
}

#frmlookupstock tbody tr {
  width: 100%;
  border: 1px solid gray;
  padding: 5px 0px;
}

#frmlookupstock tbody tr:hover {
  background-color: blue;
  color: white;
}

#frmlookupstock tbody tr:nth-child(even) {
  background-color: lightskyblue;
}

#frmlookupstock tbody tr:nth-child(even):hover {
  background-color: blue;
  color: white;
}

#frmlookupstock td button {
  height: 18px;
  width: 60px;
}

#frmlookupstock tbody td {
  border-right: 1px solid gray;
}