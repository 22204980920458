.frmlookupcompany-divmain {
    height: 88vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  
  #frmlookupcompany .table-container {
    width: 80%;
    height: 100%;
    margin: auto;
    text-align: center;
  }
  
  #frmlookupcompany .searchtext {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    margin-bottom: 6px;
  }
  
  #frmlookupcompany .search-input {
    height: 20px;
    padding: 10px;
    width: 50%;
    font-size: 16px;
  }
  
  #frmlookupcompany .searchtext button {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    font-size: 14px;
  }
  
  #frmlookupcompany .mybtncontainer {
    display: flex;
    justify-content: center;
  }
  
  #frmlookupcompany .mybtncontainer .mybtn {
      margin: 0 2px;
      padding: 8px 10px;
      background-color: #007bff;
      color: white;
      border: none;
      cursor: pointer;
      font-size: 14px;
      width: auto;
  }
  
  #frmlookupcompany .mybtncontainer .btn-icon {
    padding-right: 2px;
  }
  
  #frmlookupcompany .mybtncontainer .mybtn:hover {
    background-color: #0056b3;
  }
  
  #frmlookupcompany .lookupTable {
    width: 100%;
    height: 85%;
    max-height: 85%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border: 1px solid gray;
  }
  
  #frmlookupcompany .tabledata {
    width: 100%;
    height: auto;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  #frmlookupcompany .tabledata th,
  .table td {
    border: 1px solid #ddd;
    padding: 2px;
  }
  
  #frmlookupcompany .tabledata th {
    background-color: blue;
    color: white;
  }
  
  #frmlookupcompany .row-data td {
    overflow: hidden;
  }
  
  #frmlookupcompany .col-actname {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    width: 50%;
    padding-left: 2px;
  }
  
  #frmlookupcompany .col-place {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    width: 20%;
    padding-left: 2px;
  }
  
  #frmlookupcompany .col-gstin {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    width: 15%;
    padding-left: 2px;
  }
  
  #frmlookupcompany .col-action {
    width:10%;
  }
  
  #frmlookupcompany tbody tr {
    width: 100%;
    border: 1px solid gray;
    padding: 5px 0px;
  }
  
  #frmlookupcompany tbody tr:hover {
    background-color: blue;
    color: white;
  }
  
  #frmlookupcompany tbody tr:nth-child(even) {
    background-color: lightskyblue;
  }
  
  #frmlookupcompany tbody tr:nth-child(even):hover {
    background-color: blue;
    color: white;
  }
  
  #frmlookupcompany td button {
    height: 18px;
    width: 60px;
  }
  
  #frmlookupcompany tbody td {
    border-right: 1px solid gray;
  }
  