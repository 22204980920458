.company-divmain{
    width: 100vw;
    height: 88vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background: rgba(243, 243, 243, 0.5);
}
.company-container{
    width: 30%;
    max-width: 400px;
    height: 20%;
    max-height: 100px;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.company-container .company-inputgrp{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.company-container .company-inputgrp input{
    width: 70%;
    max-width: 500px;
    padding: 4px;
    text-transform: capitalize;
}
.company-container .company-btngrp{
    width: 50%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.company-container .company-btngrp button{
    width: 80px;
    height: 100%;
    background-color: rgb(108, 108, 252);
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease,transform 0.3s ease;
}
.company-container .company-btngrp button:hover {
    background-color: rgb(80, 80, 230);
    box-shadow: 0 0 5px rgba(80, 80, 230, 0.7);
    transform: scale(1.02);
  }