.mypageflashnews {
    height:88vh;
    width: 100%;
    padding: 0px;
    background-image: radial-gradient(circle at 50% -20.71%, #fffff8 0, #feffe4 25%, #e6f2cd 50%, #cde0b7 75%, #b7d3a7 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.mypagewindow{
    width: 85%;
    height: 88vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.mypagewindow h1{
    font-size: 20px;
    color: blue;
}
.mainflashnews {
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mainflashnews textarea{
    width: 50%;
}
.mainflashnews textarea::placeholder {
    color:lightblue;
}
.flashnews-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.flashnews-gstr{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.flashnews-grp{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.flashnews-grp input{
    width: 80%;
    height: auto;
    font-size: 16px;
    padding: 5px;
}

.flashnews-grp textarea{
    width: 80%;
    height: 40px;
    text-align: center;
    /* margin: 10px 0px; */
    font-size: 16px;
}
.mainflashnews textarea{
    width: 100%;
    height: 50px;
    text-align: center;
    margin: 20px 0px;
    font-size: 18px;
    font-weight: 600;
    
}
.flashnews-btngrp button{
    width: 30%;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid blue;
    outline: none;
    transition: 0.5s;
}
.flashnews-btngrp button:hover{
    background-color: blue;
    border: 1px solid white;
    color: white;
}
.flashnews-btngrp{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 10px 0;
}
