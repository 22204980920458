/* CheckboxCircle.css */

.checkbox-circle {
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
    /* background-color: red; */
  }
  
  .checkbox-circle input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkmark {
    position: absolute;
    top: -6px;
    left: -3px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 50%;
  }
  
  .checkbox-circle input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkbox-circle input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkbox-circle .checkmark:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  