.frmSelect-mainpage {
  height: 94vh;
  width: 45.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border: 10px solid transparent; /* Create space for the border */
  border-image: linear-gradient(
      to right,
      #fad0c4,
      /* Light Pink */ #f9e4b7,
      /* Light Yellow */ #d3f8e2,
      /* Light Mint Green */ #a7d8ff,
      /* Light Sky Blue */ #d1c6f2,
      /* Light Lavender */ #ffb3e6,
      /* Light Purple-Pink */ #fffacd /* Light Lemon Yellow */
    )
    1;
}
.frmSelect-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.frmSelect-main .search-select-cancel {
  width: 53%;
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-left: 2px;
  position: relative;
  gap: 30px;
}

.frmSelect-main .search input {
  width: 300px;
  margin-left: 5px;
  border: 1px solid gray;
}

.frmSelect-main .detailfromselectrow {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.frmSelect-main .custom-header .ag-header-cell {
  height: 120px; /* Adjust the height as needed */
  line-height: 40px; /* Align text vertically */
}

.frmSelect-main .custom-header .ag-header-cell-label {
  padding-top: 0; /* Remove default padding if needed */
}

.frmSelect-main .frmSelect-bottom {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  justify-content: space-between;
}

.frmSelect-main .frmSelect-bottom .search-container {
  display: flex;
  align-items: center;
  width: 75%;
  height: 100%;
  justify-content: space-between;
  margin-left: 5px;
}

.frmSelect-main .frmSelect-bottom .search-container .search-input {
  width: 300px;
  border: none;
  border-bottom: 2px solid grey;
  outline: none;
}

.frmSelect-main .frmSelect-bottom .search-container .search-icon {
  position: relative;
  top: 50%;
  left: -30%;
  transform: translateY(-100%);
  color: #999; /* Color of the search icon */
  pointer-events: none; /* Ensure the icon doesn't interfere with input focus */
}

.frmSelect-main .frmSelect-bottom .btngroup {
  margin-left: 35px;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.frmSelect-main .frmSelect-bottom .btngroup .editgrp, .selectgrp{
  width: 50%;
  height: 100%;
  display: flex;
  gap: 5px;
}

.frmSelect-main .frmSelect-bottom  .btngroup .btn {
  width: 100px;
  height: 100%;
  background-color: #3498db;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-left: 10px;
  margin-top: 0px;
}
.frmSelect-main .frmSelect-bottom .btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.frmSelect-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.frmSelect-modal-content {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.aggrid-selecteditem {
  background-color: #0033cc !important; /* Dark royal blue */
  color: white; /* Optional: Change text color for contrast */
}
