.myuploadpdfpage {
    height:88vh;
    width: 100%;
    padding: 0px;
    background-image: radial-gradient(circle at 50% -20.71%, #fffff8 0, #feffe4 25%, #e6f2cd 50%, #cde0b7 75%, #b7d3a7 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.myuploadpdfpagewindow{
    width: 30%;
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    border: 1px solid black;
    border-radius: 10px;
    gap: 10px;
    min-width: 250px;
    min-height: 150px;
}
.myuploadpdfpage .inputfilepanel{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.myuploadpdfpage .inputfilepanel input{
    width: 100%;
    border: none;
    padding: 8px 0px;
    border-radius: 10px;
    font-size: 17px;
}
.myuploadpdfpage .btngroup{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.myuploadpdfpage .btngroup button{
    width: 120px;
    height: 35px;
    border: none;
    padding: 4px 8px;
    border-radius: 10px;
    background-color: lightblue;
}

.myuploadpdfpage .btngroup button:hover{
    background-color: blue;
    border: 1px solid white;
    color: white;
}

