.pricelist-divmain{
    height: 81vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.pricelist-divmain h2 {
  margin-bottom: 5px;
} 

.pricelist-form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 15px 20px 20px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    border: 1px solid rgba(86, 216, 254, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

  }
  
  .pricelist-form {
    display: flex;
    flex-direction: column;
  }
  
  .pricelist-form-group {
    display: flex;
    align-items: center;
    justify-content:flex-start;
    margin: 10px 0PX;

  }
  
  .pricelist-divmain label {
    width: 60%;
    font-weight: bold;
    margin-right: 10px;
    text-align: start;
  }
  .pricelist-divmain label[title]{
    width: 36%;
  }
  #pricelistserial{
    width: 60PX;
  }
  .pricelist-form-group input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid black;
    font-size: 16px;
    height: 30px;
  }
  .updateBack{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .pricelist-divmain button {
    width: 90px;
    height: 35px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 8px;
  }
  
  .pricelist-divmain button:hover {
    background-color: #0056b3;
  }
  
  input[type="number"]::-webkit-outer-spin-button, 
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }