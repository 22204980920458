* { 
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  height:100vh;
  width:100vw;
  overflow-x: hidden;
}

