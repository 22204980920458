.footer12{
    width:100%;
    height:6vh;
    display:flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #068080;
    padding:  0px 3%;
}
.footer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(6, 128, 128);
}
.footer2{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer13{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer13 p{
    margin-right: 10px;
}
.footer p {
    color:#f5f5f5 ;
    font-size:18px;
}