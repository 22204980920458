.deliverychalan-divmain {
  width: 100vw;
  height: 88vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.deliverychalan-container {
  width: 80%;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.deliverychalan-container input{
  text-transform: capitalize;
}
.deliverychalan-header {
  width: 100%;
  height: 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lightgoldenrodyellow;
  border: 1px solid black;
  padding: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.deliverychalan-top {
  width: 100%;
  min-height: 90px;
  height: 19%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: rgb(246, 225, 248);
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.deliverychalan-topleft,
.deliverychalan-topright {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 10px;
  gap: 2px;
}
.deliverychalan-inputgrp {
  width: 100%;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.deliverychalan-inputgrp label {
  width: 15%;
}
.deliverychalan-inputgrp2 {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
}
.deliverychalan-top input {
  width: 85%;
  padding: 0 2px;
}
.deliverychalan-top select {
  width: 85%;
  /* height: 20px; */
}
.deliverychalan-top2 {
  width: 100%;
  min-height: 30px;
  height: 4%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: rgb(246, 225, 248);
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.deliverychalan-top2left {
  display: flex;
  padding: 0 10px;
}
.deliverychalan-top2left .deliverychalan-inputgrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.deliverychalan-inputgrp input {
  padding: 0 2px;
}
.deliverychalan-inputgrp button {
  width: 55px;
  height: 18px;
  background-color: rgb(108, 108, 252);
  border-radius: 5px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,transform 0.3s ease;
  margin-left: 2px;
}
.deliverychalan-inputgrp button:hover {
  background-color: rgb(80, 80, 230);
  box-shadow: 0 0 5px rgba(80, 80, 230, 0.7);
  transform: scale(1.02);
}
.deliverychalan-aggrid {
  width: 100%;
  height: 73%;
  background-color: rgb(246, 225, 248);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.deliverychalan-top2 button {
  width: 20px;
  height: 18px;
  background-color: rgb(108, 108, 252);
  border-radius: 5px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
  margin-left: 2px;
}
.deliverychalan-top2 button:hover {
  background-color: rgb(80, 80, 230);
  box-shadow: 0 0 5px rgba(80, 80, 230, 0.7);
  transform: scale(1.02);
}
.deliverychalan-editmodal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deliverychalan-editmodal-container {
  position: relative;
  height: 8%;
  top: 0;
  left: 0;
  width: 79%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  padding: 3px;
  max-height: 150px;
}
.editfields-inputgrp input {
  padding: 0 2px;
}
.editfields-btngrp {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.editfields-btngrp button {
  width: 80px;
  height: 20px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 5px;
}
.editfields-btngrp .ok-btn {
  background-color: #28a745;
  color: white;
  border: none;
}

.editfields-btngrp .cancel-btn {
  background-color: #dc3545;
  color: white;
  border: none;
}
.deliverychalan-editmodal-button {
  position: absolute;
  top: 0px;
  right: 3px;
  background: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: red;
  z-index: 999;
}
.numericformatinput{
    text-align: right;
}