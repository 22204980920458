.divmain{
    height: 81vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.prodet{
    position: relative;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    justify-content: space-evenly;
    background: rgba(236, 237, 233, 0.3);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(86, 216, 254, 0.2);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    text-align: left;
    padding: 20px 20px 20px 20px;
}
.prodetlabel{
    width: 90px;
    margin-right: 10px;
    font-size: 14px;
}

.prodetlabel2{
    width: 60px;
    margin-right: 2px;
    font-size: 14px;
}

.prodetlabel3{
    width: 100px;
    margin-left:8px;
    font-size: 14px;
    margin-right: 2px;
}

.divproductdetailsbtns{
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-end;
    padding-top: 5px;
}
.prodetbtn{
    background-color: #3498DB;
    border: none;
    color: white;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transition: transform 0.3s ease;   
    margin-left: 30px;
}
.prodetbtn:hover{
    transform: scale(1.05);
}
.divproductdetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 5px;
}
.prodetinp1{
    width: 270px;
    margin-right: 10px;
}
.prodetailinp1{
    width: 380px;
}
.prodetailinp2{
    width: 175px;
}
.prodetnumb{
    width: 60px;
    margin-right: 12px;
}
.prodet h1{
    width: 100%;
    font-size: 25px;
}

.prodet input[type="number"] {
    text-align: right;
}

.prodet input, .prodet select {
    height: 25px;
    font-size: 14px;
    padding-left: 5px;
    font-weight: 600;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.mySelect__value-container{
    height: 35px;
    padding-top: -10px;
   }
  