.frmcompany-divmain{
    height: 88vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.frmcompany-main {
    position: relative;
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    backdrop-filter: blur(10px);
    text-align: left;
    font-weight: 600;
    font-size: 12px;
}

.frmcompany input[type="number"] {
    text-align: right;
}

.frmcompany input[type="number"]::-webkit-outer-spin-button, 
.frmcompany input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.frmcompany-leftpanel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.frmcompany-group input {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
    font-size: 14px;
    height: 19px;
  }

.frmcompany-container-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: 95%;
    background: rgba(236, 237, 233, 0.3);
    border: 1px solid rgba(86, 216, 254, 0.2);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    flex-direction: column;
}

.frmcompany-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height:100%;
    padding: 10px;
}

.frmcompany-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 2px;
    margin-right: 0px;
}

.frmcompany-group label{
    font-size: 12px;
}
.frmcompany-label {
    width: 130px;
    margin-right: 10px;
}

#frmcompany-label-pan{
    width: 30px;
    margin-right: 10px;
    margin-left:10px;    
    text-align: left;
    align-items: center;
}

.frmcompany-input {
    width: 100%;
    padding: 5px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
}

.frmcompany-group-sub  {
    width:100%;    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0px;
}

.frmcompany-group-grid {
    width:100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 20PX;
    margin-bottom: 2px;
    margin-right: 0px;
}

#frmcompany-place {
    width:50%;
    padding: 5px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 3px;
}

#frmcompany-sub3{
    width:100%;
    padding: 8px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 3px;
}

#frmcompany-label-pincode{
    width: 120px;
    margin-right: 10px;
    margin-left:20px;
    text-align: right;
    align-items: center;
}

#frmcompany-pincode {
    width: 30%;
    padding: 5px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 3px;
}

#frmcompany-pan {
    width: 50%;
    padding: 5px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 3px;
}

#frmcompany-mobile {
    width:70%;
    padding: 5px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 3px;
}

.fa-whatsapp-icon{
    font-size: 22px;
    margin: 0px 3px;
}
.cricleicon{
    width: 20px;
}
.frmcompany-group-sub2  {
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* margin-bottom: 2px; */
    margin-right: 0px;
}

.frmcompany-group .fawhatsapp-icon {
    width:20%;
    font-weight: 600;
    font-size: 16px;
   
}

.frmcompany-group .fa-whatsapp-checkbox {
    width:10%;
    font-size: 20px;
}
.frmcompany-group-iecode-explic {
    display: flex;
    justify-content: flex-start;
}

.frmcompany-group select {
    padding-left:5px;
    height: 18px;
    width: 100%;
    font-size: 12px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 3px;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 12px;
    cursor: pointer;
}

.frmcompany select:focus {
    outline: none;
    border-color: #007bff;
}


.frmcompany-group input[type="date"] {
    width: 120px;
}

.frmcompany .label.required::after {
    content: '*';
    margin-left: 4px;
    color: red;
}

.frmcompany-header {
    height:auto;
    font-size: 12px;
    text-align: center;
    padding: 5px 10px;
    width:100%;
    color:whitesmoke;
    background-color:rgb(138, 125, 127);
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.frmcompany-btngroup {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    padding-top:5px;
}

.frmcompany-btn {
    background-color: #3498DB;
    height:30px;
    border: none;
    color: white;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transition: transform 0.3s ease;   
    margin-left: 10px;
}

.frmcompany-btn:hover{
    transform: scale(1.05);
}
