.frmlookuptransport-divmain {
    height: 88vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  
  #frmlookuptransport .table-container {
    width: 60%;
    height: 100%;
    margin: auto;
    text-align: center;
  }
  
  #frmlookuptransport .searchtext {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    margin-bottom: 6px;
  }
  
  #frmlookuptransport .search-input {
    padding: 10px;
    width: 50%;
    height: 20px;
    font-size: 16px;
  }
  
  #frmlookuptransport .searchtext button {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    font-size: 14px;
  }
  
  #frmlookuptransport .mybtncontainer {
    display: flex;
    justify-content: center;
  }
  
  #frmlookuptransport .mybtncontainer .mybtn {
    margin: 0 2px;
    padding: 8px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 12px;
    width: auto;
  }
  
  #frmlookuptransport .mybtncontainer .btn-icon {
    padding-right: 2px;
  }
  
  
  #frmlookuptransport .mybtncontainer .mybtn:hover {
    background-color: #0056b3;
  }
  
  #frmlookuptransport .lookupTable {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border: 1px solid gray;
  }
  
  #frmlookuptransport .tabledata {
    width: 100%;
    height: auto;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  #frmlookuptransport .tabledata th,
  .table td {
      border: 1px solid #ddd;
      padding: 2px;
  }
    
  #frmlookuptransport .tabledata th {
    background-color: blue;
    color: white;
  }
  
  #frmlookuptransport .row-data td {
    overflow: hidden;
  }
  
  #frmlookuptransport .col-transportname {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 136px;
      width: 35%;
      padding-left: 2px;
    }    

  #frmlookuptransport .col-transportgstin {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 85px;
    width: 20%;
    padding-left: 2px;
  }

  #frmlookuptransport .col-transportid {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 136px;
    width: 15%;
    padding-left: 2px;
    min-width: 140px;
  }

  #frmlookuptransport .col-mobile {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 136px;
    width: 15%;
    padding-left: 2px;
  }

  
  #frmlookuptransport .col-action {
    width: 15%;
  }

  #frmlookuptransport tbody tr {
    width: 100%;
    border: 1px solid gray;
    padding: 5px 0px;
  }
  
  #frmlookuptransport tbody tr:hover {
    background-color: blue;
    color: white;
  }
  
  #frmlookuptransport tbody tr:nth-child(even) {
    background-color: lightskyblue;
  }
  
  #frmlookuptransport tbody tr:nth-child(even):hover {
    background-color: blue;
    color: white;
  }
  
  #frmlookuptransport td button {
    height: 18px;
    width: 60px;
  }
  
  #frmlookuptransport tbody td {
    border-right: 1px solid gray;
  }