.frmlookuppricelist-divmain {
    height: 88vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  
  #frmlookuppricelist .table-container {
    width: 50%;
    height: 100%;
    margin: auto;
    text-align: center;

  }
  
  #frmlookuppricelist .searchtext {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  
  #frmlookuppricelist .search-input {
    padding: 10px;
    height: 20px;
    width: 50%;
    font-size: 16px;
  }
  
  #frmlookuppricelist .searchtext button {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    font-size: 14px;
  }
  
  #frmlookuppricelist .mybtncontainer {
    display: flex;
    justify-content: center;
  }
  
  #frmlookuppricelist .mybtncontainer .mybtn {
    margin: 0 2px;
    padding: 8px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 12px;
    width: auto;
  }
  
  #frmlookuppricelist .mybtncontainer .btn-icon {
    padding-right: 2px;
  }
  
  
  #frmlookuppricelist .mybtncontainer .mybtn:hover {
    background-color: #0056b3;
  }
  
  #frmlookuppricelist .lookupTable {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border: 1px solid gray;
    
  }
  
  #frmlookuppricelist .tabledata {
    width: 100%;
    height: auto;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  #frmlookuppricelist .tabledata th,
  .table td {
      border: 1px solid #ddd;
      padding: 2px;
  }
    
  #frmlookuppricelist .tabledata th {
    background-color: blue;
    color: white;
  }
  
  #frmlookuppricelist .row-data td {
    overflow: hidden;
  }
  
  #frmlookuppricelist .col-pricelistname {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 136px;
    width: 70%;
    padding-left: 2px;
  }

  #frmlookuppricelist .col-pricelistserial {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 136px;
    width: 10%;
    padding-left: 2px;
  }
  
  #frmlookuppricelist .col-action {
    width: 20%;
  }
  
  #frmlookuppricelist tbody tr {
    width: 100%;
    border: 1px solid gray;
    padding: 5px 0px;
  }
  
  #frmlookuppricelist tbody tr:hover {
    background-color: blue;
    color: white;
  }
  
  #frmlookuppricelist tbody tr:nth-child(even) {
    background-color: lightskyblue;
  }
  
  #frmlookuppricelist tbody tr:nth-child(even):hover {
    background-color: blue;
    color: white;
  }
  
  #frmlookuppricelist td button {
    height: 18px;
    width: 60px;
  }
  
  #frmlookuppricelist tbody td {
    border-right: 1px solid gray;
  }