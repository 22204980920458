.emptypage1 {
    height:76vh;
    width: 100%;
    padding: 0px;
    background-image: radial-gradient(circle at 50% -20.71%, #fffff8 0, #feffe4 25%, #e6f2cd 50%, #cde0b7 75%, #b7d3a7 100%);
    box-sizing: border-box;
    display:flex;
    align-items: center;
    justify-content:space-between;
  }

  .mypage {
    height:81vh;
    width: 100%;
    padding: 0px;
    background-image: radial-gradient(circle at 50% -20.71%, #fffff8 0, #feffe4 25%, #e6f2cd 50%, #cde0b7 75%, #b7d3a7 100%); 
    display: flex;
    align-items: center;
    justify-content: center;
}