.DeliveryChalan-divmain {
  height: 88vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.DeliveryChalan-container {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  min-height: 350px;
  background-color: whitesmoke;
  border: 10px solid gray;
  border-radius: 10px;
}
.DeliveryChalan-top {
  width: 97%;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DeliveryChalan-btngrp {
  width: 97%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.DeliveryChalan-checkboxgrp {
  width: 3%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}
.DeliveryChalan-search {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.DeliveryChalan-btngrp-btn {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.DeliveryChalan-btngrp button {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}
.DeliveryChalan-btngrp button:hover {
  transform: scale(1.05);
  background-color: blue;
  color: white;
}
.ag-checkbox-input {
  width: 20px; /* Set custom width for the checkbox */
  height: 20px; /* Set custom height for the checkbox */
}
.aggrid-selecteditem {
  background-color: #0033cc !important; /* Dark royal blue */
  color: white; /* Optional: Change text color for contrast */
}

.aag-row-even {
  background-color: #fd8787; /* Light grey for even rows */
}

/* Style for odd rows */
.aag-row-odd {
  background-color: #ffffff; /* White for odd rows */
}

.DeliveryChalan-divmain .ewaymodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Box */
.DeliveryChalan-divmain .ewaymodal-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Modal Header */
.DeliveryChalan-divmain .ewaymodal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.DeliveryChalan-divmain .ewaymodal-header h2 {
  margin: 0;
}

/* Close Button */
.DeliveryChalan-divmain .eway-closebtn {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.DeliveryChalan-divmain .ewaymodal-body,
.DeliveryChalan-divmain .eway-top,
.DeliveryChalan-divmain .eway-bottom {
  margin-top: 15px;
}

.DeliveryChalan-divmain .eway-top {
  border-bottom: 1px solid #ccc;
}

.DeliveryChalan-divmain .ewayform-group1 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.DeliveryChalan-divmain .ewayform-group1 label {
  width: 30%;
  font-weight: 400;
}
.DeliveryChalan-divmain .ewayform-group1 input {
  width: 25%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 2px;
  color: darkblue;
  font-weight: 600;
}

/* Form Group */
.DeliveryChalan-divmain .ewayform-group {
  margin-bottom: 15px;
}

.DeliveryChalan-divmain .ewayform-group label {
  display: block;
  margin-bottom: 5px;
}

.DeliveryChalan-divmain .ewayform-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Modal Footer */

.DeliveryChalan-divmain .eway-btngrp {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
  padding-top: 8px;
  gap: 10px;
}

/* Buttons */
.DeliveryChalan-divmain .open-modal-btn,
.DeliveryChalan-divmain .ok-btn,
.DeliveryChalan-divmain .cancel-btn {
  padding: 2px 8px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.DeliveryChalan-divmain .open-modal-btn {
  background-color: #007bff;
  color: white;
}
.DeliveryChalan-divmain .ok-btn {
  width: 50px;
  background-color: #28a745;
  color: white;
  /* margin-left: 10px; */
}

.DeliveryChalan-divmain .cancel-btn {
  width: 50px;
  background-color: #dc3545;
  color: white;
}
