.RainbowPanel-divmain {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0,0,0,0.1);
}
.RainbowPanel-main {
  width: 60%;
  height: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border: 5px solid transparent; /* Create space for the border */
  border-color: rgb(139, 0, 125);
  max-height: 400px;
  max-width: 800px;
}
.RainbowPanel-left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: lightblue;
  border: 2px solid transparent; /* Create space for the border */
  border-color: rgb(139, 0, 125);
  gap: 2px;
  padding: 5px;
}

.RainbowPanel-left-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.RainbowPanel-left-row label {
  width: 35%;
}
.RainbowPanel-left-row input {
  width: 65%;
  padding: 2px 5px;
  font-weight: 600;
}

.RainbowPanel-right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: lightblue;
  border: 2px solid transparent; /* Create space for the border */
  border-color: rgb(139, 0, 125);
  gap: 2px;
  padding: 5px;
}

.RainbowPanel-right-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 2px;
}

.RainbowPanel-right-row input,
.RainbowPanel-right-row button {
  padding: 2px 5px;
  font-weight: 600;
}
