.gstr2b_ims-divmain {
  width: 100vw;
  height: 88vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  /* position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.1); */
}

.gstr2b_ims-formcontainer {
  width: 98%;
  height: 98%;
  display: flex;
  flex-direction: column;
  background-color: azure;
  border: 2px solid rgb(3, 91, 88);
  border-style: double;
  border-radius: 5px;
  box-shadow: inset;
}

.gstr2b_ims-header {
  width: 100%;
  height: 4vh;
  max-height: 25px;
  background-color: rgb(255, 231, 178);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 2px solid rgb(3, 91, 88);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 6px 4px;
}

.gstr2b_ims-header h6 {
  font-size: 16px;
  margin: 2px;
}
.gstr2b_ims-toppanel {
  width: 100%;
  height: 7vh;
  max-height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  border-bottom: 2px solid rgb(3, 91, 88);
  gap: 5px;
}
.gstr2b_ims-toppanel .search-container {
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
.gstr2b_ims-toppanel .search-container .search-grp {
   width: 33%;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   gap: 2px;
}
.gstr2b_ims-toppanel .search-container .search-grp input {
  /* background-color: green; */
  width: 80%;
  height: 100%;
  border: none;
  border-bottom: 1px solid black;
  background-color: transparent;
}
.gstr2b_ims-toppanel .search-container label {
  font-size: 12px;
}
/* .search-icon{
    margin-right: 20px;
  } */

.gstr2b_ims-toppanel .top-input-grp {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 10px; */
}
.gstr2b_ims-toppanel .input-group {
  width: auto;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.gstr2b_ims-toppanel .input-group input {
  width: 75%;
  height: 25px;
  min-width: 35%;
  text-align: left;
  padding-left: 2px;
  text-transform: uppercase;
}

.gstr2b_ims-toppanel .input-group select {
  width: 100px;
  height: 25px;
  min-width: 10%;
  text-align: left;
  padding-left: 2px;
  text-transform: uppercase;
}

.gstr2b_ims-container {
  width: 100%;
  height: 92vh;
  display: flex;
  flex-direction: row;
}

.gstr2b_ims-toppanel button {
  width: 90px;
  height: 25px;
  background-color: rgb(108, 108, 252);
  border-radius: 5px;
  color: white;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
}

.gstr2b_ims-toppanel button:hover {
  background-color: rgb(80, 80, 230);
  box-shadow: 0 0 5px rgba(80, 80, 230, 0.7);
  transform: scale(1.02);
}
.gstr2b_ims-toppanel .file-upload-div {
  width: 12%;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}
.gstr2b_ims-toppanel button,
.file-upload-button {
  width: 100%;
  height: 22px;
  margin-top: 2px;
  background-color: rgb(108, 108, 252);
  border-radius: 5px;
  color: white;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
}

.gstr2b_ims-toppanel button:hover {
  background-color: rgb(80, 80, 230);
  box-shadow: 0 0 5px rgba(80, 80, 230, 0.7);
  transform: scale(1.02);
}

.gstr2b_ims-toppanel .file-upload-button:hover {
  background-color: rgb(80, 80, 230);
  box-shadow: 0 0 5px rgba(80, 80, 230, 0.7);
  transform: scale(1.02);
}
.gstr2b_ims-agegrid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: none;
  justify-content: space-between;
  background-color: white;
}

.GSTR2A-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.GSTR2A-modal-content {
  background: white;
  padding: 10px;
  border-radius: 10px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.GSTR2A-upload-container {
  padding: 2px 10px;
  width: 100%;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.GSTR2A-upload-container label {
  width: 60%;
}

.GSTR2A-upload-container button {
  width: 40%;
  height: 15px;
  padding: 3px 12px;
  margin-left: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.GSTR2A-upload-button.GSTR2A-uploaded {
  background-color: green;
  color: white;
}
.GSTR2A-upload-button.GSTR2A-uploaded:hover {
  background-color: green;
  color: white;
}
.GSTR2A-modal-content button {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.GSTR2A-btngrp {
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-top: 1px solid #ccc;
  gap: 5px;
}
.GSTR2A-btngrp .ok-btn {
  background-color: #28a745;
  color: white;
}

.GSTR2A-btngrp .cancel-btn {
  background-color: #dc3545;
  color: white;
}

.ag-header-cell {
  overflow: visible !important;
  white-space: nowrap;
}

.gstr2b_ims-mymarquee {
  width: 80% !important;
  /* animation: blink 1s infinite !important;  */
}
/* @keyframes blink {
      0% { opacity: 1; } 
      50% { opacity: 0; } 
      100% { opacity: 1; } 
  } */
