.userslist-gridpanel {
  width: 100vw;
  height: 88vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: wheat;
}
.userslist-aggrid {
  width: 95%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userslist-refresh{
  width: 100%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: gray;
  color: white;
}
