.contactform-divmain {
  height: 88vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  background-color: wheat;
}
.contactform-container {
  width: 30%;
  height:95%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-info{
  background-color: whitesmoke;
  border: 2px solid gray;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 400px;
  color: rgb(70, 3, 77);
}
.contact-btn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.contactform-divmain .bankaccount-info{
  width: 100%;
  max-height: 160px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(199, 209, 212);
  padding-top: 2px;
  border-top: 2px solid gray;
}

.bankaccount-info .bank-inf{
  min-width: 500px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  position: relative;
  min-width: 500px;
  height:100%;
  position: relative;
}


.bankaccount-info .QRCode-inf{
  min-width:80px;
  height: 100%;
  position: relative;
}

.bankaccount-info .contact-inf{
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 500px;
}
