.frmlookupacchead-divmain {
  height: 88vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

#frmlookupacchead .table-container {
  width: 80%;
  height: 100%;
  margin: auto;
  text-align: center;
}

#frmlookupacchead .searchtext {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  margin-bottom: 6px;
}

#frmlookupacchead .search-input {
  height: 20px;
  padding: 2px;
  width: 50%;
  font-size: 16px;
}

#frmlookupacchead .searchtext button {
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  font-size: 14px;
}

#frmlookupacchead .mybtncontainer {
  display: flex;
  justify-content: center;
}

#frmlookupacchead .mybtncontainer .mybtn {
  margin: 0 2px;
  padding: 8px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  width: auto;
}

#frmlookupacchead .mybtncontainer .btn-icon {
  padding-right: 2px;
}

#frmlookupacchead .mybtncontainer .mybtn:hover {
  background-color: #0056b3;
}

#frmlookupacchead .lookupTable {
  width: 100%;
  height: 85%;
  max-height: 85%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border: 1px solid gray;
}

#frmlookupacchead .tabledata {
  width: 100%;
  height: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
}

#frmlookupacchead .tabledata th,
.table td {
  border: 1px solid #ddd;
  padding: 2px;
}

#frmlookupacchead .tabledata th {
  background-color: blue;
  color: white;
}

#frmlookupacchead .row-data td {
  overflow: hidden;
}

#frmlookupacchead .col-actname {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85px;
  width: auto;
  padding-left: 2px;
}

#frmlookupacchead .col-place {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85px;
  width: 15%;
  padding-left: 2px;
}
#frmlookupacchead .col-mobile {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85px;
  width: 15%;
  padding-left: 2px;
}

#frmlookupacchead .col-gstin {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85px;
  width: 15%;
  padding-left: 2px;
}

#frmlookupacchead .col-action {
  text-align: center;
  width: 10%;
}

#frmlookupacchead tbody tr {
  width: 100%;
  border: 1px solid gray;
  padding: 5px 0px;
}

#frmlookupacchead tbody tr:hover {
  background-color: blue;
  color: white;
}

#frmlookupacchead tbody tr:nth-child(even) {
  background-color: lightskyblue;
}

#frmlookupacchead tbody tr:nth-child(even):hover {
  background-color: blue;
  color: white;
}

#frmlookupacchead td button {
  height: 18px;
  width: 60px;
}

#frmlookupacchead tbody td {
  border-right: 1px solid gray;
}
