.App {
  text-align: left;
  width:100vw;
  height:100vh;
  overflow-x:hidden;
  font-family: cambria;
}

.col-action-div {
  display: flex;
  justify-content: center; 
  align-items: center;      
  height: 100%;           
}

.col-action-div button {
  width: 50px;
  color: white;
  height: 70%;             
  flex: 1;               
  margin: 2px;           
  padding: 0;           
  display: flex;
  justify-content: center; 
  align-items: center;     
  border: none;
}
.col-action-div button:nth-of-type(1) {
  background-color: green; /* Edit button */
}

.col-action-div button:nth-of-type(2) {
  background-color: red; /* Delete button */
}
.col-action-div button:nth-of-type(3) {
  background-color: rgb(94, 56, 190); /* Print button */
}
.col-action-div button:nth-of-type(4) {
  background-color: darkorange; /* Cancel button */
}
.col-action-div button:nth-of-type(5) {
  background-color: darkviolet; /* Cancel button */
}

/* Hover effect for both buttons */
.col-action-div button:hover {
  background-color: blue;
}
.custom-header {
  text-align: center;
  background: gainsboro;
  transition: background-color 0.3s; 
  margin: 0;
  padding: 0;
}
.custom-header:hover {
  background-color: gainsboro !important; /* Force hover color */
}

.action-cell {
  display: flex;
  align-items: center;    /* Center vertically */
  justify-content: center; /* Center horizontally */
  gap: 1px;                /* Space between buttons */
  height: 100%;            /* Full height of cell */
}

.action-cell button {
  padding: 3px 5px;
  font-size: 11px;
  cursor: pointer;
  border: none;            /* Remove default border */
  border-radius: 8px;     /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth hover transition */
}



