.gstr1view-divmain {
  width: 100vw;
  height: 88vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.gstr1view-formcontainer {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: azure;
  border: 2px solid rgb(3, 91, 88);
  border-style: double;
  border-radius: 5px;
  box-shadow: inset;
}

.gstr1view-header {
  width: 100%;
  height: 4vh;
  max-height: 25px;
  background-color: rgb(255, 231, 178);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 2px solid rgb(3, 91, 88);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 6px 4px;
}

.gstr1view-header h6 {
  font-size: 16px;
  margin: 2px;
}
.gstr1view-toppanel {
  width: 100%;
  height: 7vh;
  max-height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  border-bottom: 2px solid rgb(3, 91, 88);
  gap: 5px;
}
.gstr1view-toppanel .input-group {
  width: 20%;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.gstr1view-toppanel .input-group input {
  width: 75%;
  height: 25px;
  min-width: 35%;
  text-align: left;
  padding-left: 2px;
  text-transform: uppercase;
}

.gstr1view-toppanel .input-group select {
  width: 10%;
  height: 25px;
  min-width: 10%;
  text-align: left;
  padding-left: 2px;
  text-transform: uppercase;
}

.gstr1view-container {
  width: 100%;
  height: 92vh;
  display: flex;
  flex-direction: row;
}

.gstr1view-toppanel button{
  width: 90px;
  height: 25px;
  background-color: rgb(108, 108, 252);
  border-radius: 5px;
  color: white;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
}

.gstr1view-toppanel button:hover {
  background-color: rgb(80, 80, 230);
  box-shadow: 0 0 5px rgba(80, 80, 230, 0.7);
  transform: scale(1.02);
}
.gstr1view-toppanel .file-upload-div {
  width: 12%;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}
.gstr1view-toppanel button,
.file-upload-button {
  width: 100%;
  height: 22px;
  margin-top: 2px;
  background-color: rgb(108, 108, 252);
  border-radius: 5px;
  color: white;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
}

.gstr1view-toppanel button:hover {
  background-color: rgb(80, 80, 230);
  box-shadow: 0 0 5px rgba(80, 80, 230, 0.7);
  transform: scale(1.02);
}

.gstr1view-toppanel .file-upload-button:hover {
  background-color: rgb(80, 80, 230);
  box-shadow: 0 0 5px rgba(80, 80, 230, 0.7);
  transform: scale(1.02);
}
.gstr1view-agegrid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: none;
  justify-content: space-between;
  background-color: white;
}
