.frmlookuppdtmstr-divmain {
    height: 88vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  
  #frmlookuppdtmstr .table-container {
    width: 80%;
    height: 100%;
    margin: auto;
    text-align: center;
  }
  
  #frmlookuppdtmstr .searchtext {
    width: 100%;   
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    margin-bottom: 6px;
  }
  
  #frmlookuppdtmstr .search-input {
    padding: 10px;
    height: 20px;
    width: 50%;
    font-size: 16px;
  }
  
  #frmlookuppdtmstr .searchtext button {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    font-size: 14px;
  }
  
  #frmlookuppdtmstr .mybtncontainer {
    display: flex;
    justify-content: center;
  }
  
  #frmlookuppdtmstr .mybtncontainer .mybtn {
    margin: 0 2px;
    padding: 8px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 12px;
    width: auto;
  }
  
  #frmlookuppdtmstr .mybtncontainer .btn-icon {
    padding-right: 2px;
  }
  
  
  #frmlookuppdtmstr .mybtncontainer .mybtn:hover {
    background-color: #0056b3;
  }
  
  #frmlookuppdtmstr .lookupTable {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border: 1px solid gray;
  }
  
  #frmlookuppdtmstr .tabledata {
    width: 100%;
    height: auto;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  #frmlookuppdtmstr .tabledata th,
  .table td {
      border: 1px solid #ddd;
      padding: 2px;
  }
    
  #frmlookuppdtmstr .tabledata th {
    background-color: blue;
    color: white;
  }
  
  #frmlookuppdtmstr .row-data td {
    overflow: hidden;
  }
  
  #frmlookuppdtmstr .col-pdtname {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 136px;
    width: 50%;
    padding-left: 2px;
  }

  #frmlookuppdtmstr .col-pdtuofm {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10px;
    width: 5%;
    padding-left: 2px;
  }
  #frmlookuppdtmstr .col-pdtcasecontent {
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10px;
    width: 5%;
    padding-right: 2px;
  }

  #frmlookuppdtmstr .col-pdtcaseuofm {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10px;
    width: 5%;
    padding-left: 2px;
  }

  #frmlookuppdtmstr .col-pdtserial {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10px;
    width: 5%;
  }

  #frmlookuppdtmstr .col-pdtgroupname {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 136px;
    width: 18%;
    padding-left: 2px;
  }
  
  #frmlookuppdtmstr .col-action {
    width: 12%;
  }
  
  #frmlookuppdtmstr tbody tr {
    width: 100%;
    border: 1px solid gray;
    padding: 5px 0px;
  }
  
  #frmlookuppdtmstr tbody tr:hover {
    background-color: blue;
    color: white;
  }
  
  #frmlookuppdtmstr tbody tr:nth-child(even) {
    background-color: lightskyblue;
  }
  
  #frmlookuppdtmstr tbody tr:nth-child(even):hover {
    background-color: blue;
    color: white;
  }
  
  #frmlookuppdtmstr td button {
    height: 18px;
    width: 60px;
  }
  
  #frmlookuppdtmstr tbody td {
    border-right: 1px solid gray;
  }