.divmain{
    height: 88vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.frmtransport-main {
    position: relative;
    height: auto;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    backdrop-filter: blur(10px);
    text-align: left;
}

.frmtransport input[type="number"] {
    text-align: right;
}

.frmtransport input[type="number"]::-webkit-outer-spin-button, 
.frmtransport input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.frmtransport-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.frmtransport-group input {
    width: 100%;
    padding: 0px 8px;
    border-radius: 5px;
    border: 1px solid black;
    font-size: 12px;
    height: 25px;
  }

.frmtransport-container-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    background: rgba(236, 237, 233, 0.3);
    border: 1px solid rgba(86, 216, 254, 0.2);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    flex-direction: column;

}
.frmtransport-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
}

.frmtransport-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 2px;
    margin-right: 0px;
}

.frmtransport-label {
    width: 180px;
    margin-right: 5px;
    font-size: 14px;
}

#frmtransport-label {
    width:60%;
    font-weight: bold;
    margin-right: 10px;
    margin-left: 5px;
    text-align: start;
}
/* .frmtransport input {
    width: 100%;
    padding: 8px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 3px;
    font-weight: 600;
    font-size: 16px;
    margin-right: 5px;
} */

.frmtransport-group-sub  {
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2px;
    margin-right: 0px;
    font-size: 14px;
    
}

#frmtransport-mobile {
    width:80%;
    padding: 8px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
}

.frmtransport-container .required::after {
    content: '*';
    margin-left: 4px;
    color: red;
}

.frmtransport-header {
    font-size: 12px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    width:100%;
    color:whitesmoke;
    background-color:rgb(138, 125, 127);
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.frmtransport-btngroup {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    padding-top: 5px;
}
.frmtransport-btn {
    background-color: #3498DB;
    border: none;
    color: white;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transition: transform 0.3s ease;   
    margin-left: 30px;
}
.frmtransport-btn:hover{
    transform: scale(1.05);
}

.input-field {
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent text wrapping */
    text-overflow: ellipsis; /* Show ellipsis for overflowed text */
  }
  
  .input-field:focus {
    outline: none;
    border-color: #007bff; /* Optional: Highlight border on focus */
  }