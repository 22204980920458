.navbar {
  height: 7vh;
  width: 100%;
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
}
.navbar-hide {
  height: 7vh;
  width: 100%;
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
.navbar .navbar-header {
  color: white;
  font-size: 25px;
  text-decoration: none;
  padding-left: 1rem;
}
.navbar .navbar-item {
  color: white;
  font-size: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-decoration: none;
  list-style: none;
  background-color: blue;
  padding-left: 3%;
  padding-right: 3%;
}
.navbar .navbar-item li {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  transition: 0.5s;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 8px;
  margin: 0px 3px;
  height: 100%;
}
.linkcom {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  transition: 0.5s;
}
.navbar .navbar-item li:hover {
  background-color: orangered;
  color: #ccc;
}
.active {
  width: 100%;
  background-color: royalblue;
  color: white;
}
.active:hover {
  background-color: royalblue !important;
}
.navbar-btn {
  display: none;
}
.profile {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.user-pic {
  width: 40px;
  height: 40px;
  bottom: radius 50%;
  cursor: pointer;
  margin-left: 30px;
}
#subMenu.display {
  background-color: white;
  max-height: 400px;
}
.sub-menu {
  background: #fff;
  padding: 20px;
  margin: 10px;
}
.user-info {
  display: flex;
  align-items: center;
}
.user-info h3 {
  font-weight: 500;
}
.user-info img {
  width: 60px;
  border-radius: 50%;
  margin-right: 15px;
}
.install {
  width: auto;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.install h2 {
  font-size: 18px;
  font-weight: normal;
}
.install:hover {
  background-color: red;
}
.install:hover .droplist {
  opacity: 1;
}
.dropdown {
  height: 100%;
  position: relative;
}
.dropdown .droplist1 {
  position: absolute;
  width: 200px;
  height: auto;
  left: 0;
  top: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: 99;
}
.droplist1 .linkcom1 {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  transition: 0.5s;
  background-color: blue;
  border: 1px solid gray;
}
.droplist1 .linkcom1 li {
  width: 100%;
}
.droplist1 .linkcom1:hover {
  background-color: orangered;
  color: #ccc;
}