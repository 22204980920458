.smssettings-divmain {
  width: 100vw;
  height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.smssettings-divmain .smssettings-container {
  width: 25%;
  height: 30%;
  background-color: whitesmoke;
  border: 2px solid gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  min-height: 250px;
  min-width: 300px;
}

.smssettings-container .smssettings-top {
  width: 100%;
  height: 5%;
  max-height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: brown;
}

.smssettings-container .smssettings-bottom {
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 5px;
}

.smssettings-bottom .smssettings-inputgrp {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.smssettings-inputgrp label {
  width: 35%;
  font-size: 17px;
}

.smssettings-inputgrp input {
  width: 65%;
  padding: 3px;
}

.smssettings-bottom .smssettings-btngrp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
.smssettings-btngrp button {
  width: 100px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.smssettings-btngrp button:hover {
  transform: scale(1.05);
  background-color: blue;
  color: white;
}
