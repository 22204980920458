
.Container{
  width: 100vw;
  height: 85vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Changepassword-Container{
  width: 100vw;
  height: 88vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ContainerInner{
  width: 80%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerleft{
  width: 85%;
  height: 100%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 60;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
}

.inputs h5{
  font-size: 13px;
  margin-top: 5px;
  font-weight:normal;
  color: gray
}


.innerright {
  width: 400px;
  min-width: 200px;
  height: auto;
  border-radius: 10px;
  background-color:white;
  backdrop-filter: blur(2px);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0 8px 32px 0 gray;
}

.innerrightsignin {
  width: 400px;
  min-width: 200px;
  height: auto;
  border-radius: 10px;
  background-color:white;
  backdrop-filter: blur(2px);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0 8px 32px 0 gray;
}

.ContainerInner:hover .innerright::before{
  right: -100px;
  bottom: -100px;
}
.innerright .headicon{
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerrightsignin .headicon{
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headicon h3{
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  background:#020258;
  
}
.headicon h3 span{
  width: 100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: white;
  padding: 15px;
  transition: 0.7s;

  
}
.headicon h3 span .signupicon{
  font-size: 25px;
  color: black;
  background:#020258;
  background-position: center;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  transition: 0.7s;

}
.ContainerInner:hover .headicon h3 span .signupicon{
  color: white;
}
.ContainerInner:hover .headicon h3 span{
  background:#020258
  
}

.innerright .inputfileds{
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.innerright .inputfileds .inputs{
  width: 100%;
  height: 100%;
  margin: 10x 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-weight: 900;
  color: black;
}
.inputs input{
  color: black;
}

.innerrightsignin .inputfileds{
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.innerrightsignin .inputfileds .inputs{
  width: 100%;
  height: 100%;
  margin: 8x 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-weight: bold;
}

.inputfileds .inputs h4{
  width: 100%;
  text-align: start;
  margin-top:4px;
  margin-bottom: 4px;
  font-size: 12px;
}
.innerrightsignin .inputfileds .inputs h4{
  width: 100%;
  text-align: start;
  margin-top:2px;
  margin-bottom: 2px;
  font-size: 12px;
}
.inputfileds .inputs p{
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px;
  border-radius: 50px;
  border:2px solid gainsboro;
  transition: 0.7s;
  background-color: white;
}

.inputfileds .inputs p:hover {
  border: 2px solid #020258; /* Space added between the gradient and the width */
  border-radius: 50px;
}
.inputfileds .inputs p input{
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  margin-left: 5px;
  font-size: 15px;
  color: black;
  font-weight: 600;
}
.inputfileds .inputs p span{
  background:#020258;
  border-radius: 100%;
  padding: 5px;
  width: 30px;
  height: 25px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: center;
}
.inputfileds .inputs p span .inputicon{
  font-size: 14px;
  color: white;
}
.inputfileds .inputs p .eye{
  margin-right: 5px;
  font-size: 20px;
}
.btn{
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
}
.btn button{
  width: 110px;
  height: 30px;
  border-radius: 50px;
  border: none;
  outline: none;
  font-size: 16px;
  letter-spacing: 1px;
  background:#020258;
  color: white;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.7s;
}
.btn button span{
  width: 97%;
  height: 90%;
  background-color: white;
  color: black;
  border-radius: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.7s;

}
.inputs h4{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.inputs h4 span{
  color:red;
  font-size: 25px;
  margin-left: 2px;
}

.btn button:hover span{
  background:#020258;
  color: white;
}


.login-captcha-refresh-icon {
  font-size:25px;
  color: rgb(91, 83, 83);
}

.ContainerInner  span{
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:gainsboro;
  border: 1px solid gray;
}

.ContainerInner span:hover {
  background-color: #0056b3;
}

.login-captcha-refresh-icon:hover {
  color:white;
}

.login-captcha-inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-top:10px;
}

.login-captcha-row {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-captcha-image {
  position: relative;
  width:220px;
  height:100%;
}

.login-captcha-background {
  width: 100%;
  height:100%;
}

.login-captcha-text {
  position: absolute;
  top: 46%;
  letter-spacing: 12px;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 32px;
  font-weight: 800;
  color: #000;
  background: rgba(255, 255, 255, 0.7);
  padding: 5px;
  background: transparent;
  color: rgb(169, 165, 165);
}

.login-captcha-userinput input {
   letter-spacing: 20px;
   padding-left: 10%;
}

.required::after {
  content: '*';
  margin-left: 4px;
  color: red;
}
.loginform .forgotpassword{
  width: 80%;
  height: 20px;
  color: #0056b3;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
}
